
/**
 * App navigation configration
 * @type {Object}
 */

const navconfig = {
navList: [
    {
      title: "user profile",
      path: "/dashboard",
      show: true,
      icon: "mdi-badge-account-horizontal-outline"
    },
    {
      title: "Security",
      show: true,
      path: "/security",
      icon: "mdi-lock-outline"
    },
    {
      title: "Preferences",
      show: true,
      path: "/preferences",
      icon: "mdi-account-cog-outline"
    },
  ],
}

export default navconfig;