import Vue from "vue";
import store from "@/store";
import { determineTokenValidity, kcLogout } from "@/utils";

/**
 * Request interceptor to intercept all request sent to identity server
 * @returns {Request}
 */
export const identityRequestInterceptor = async (config) => {
  try {
    const keycloak = Vue.prototype.$keycloak;
    let token = store.getters["auth/cookieToken"]?.token;

    if (token && !keycloak.authenticated) {
      const isTokenExpired = await determineTokenValidity(token);

      if (isTokenExpired) {
        await store.dispatch("auth/updateUserSession");
        token = store.getters["auth/cookieToken"]?.token ?? "";
      }

      config.headers["Authorization"] = token;
    } else {
      const refreshBuffer = parseInt(process.env.VUE_APP_KEYCLOAK_REFRESH_TOKEN, 10) || 30;
      const idToken = keycloak.idToken
      
      try {
        await keycloak.updateToken(refreshBuffer);
        config.headers["Authorization"] = `Bearer ${keycloak.token}`;
      } catch (error) {
        console.error('Failed to refresh token:', error);
        kcLogout(idToken)
      }
    }
    return config;
  } catch (error) {
    console.warn(error);
    return config;
  }
};
