import appConfig from "@/config/app.config";
const { layouts } = appConfig;

/**
 * Routes meta
 */
const meta = {
  layout: layouts.auth,
  requiresAuth: false,
};

const publicRoutes = [
  {
    path: "/auth/action",
    name: "auth-action",
    meta,
    component: () =>
      import(
        /* webpackChunkName: "auth-action" */ "@/views/EmailActionHandler"
      ),
  },
  {
    path: "/reauthenticating",
    name: "reauthenticating",
    meta,
    component: () =>
      import(
        /* webpackChunkName: "reauthenticating" */ "@/views/ReAuthentication.vue"
      ),
  },
  {
    path: "/authentication",
    name: "authentication",
    meta,
    component: () =>
      import(
        /* webpackChunkName: "auth-issue" */ "@/views/Auth/Authentication.vue"
      ),
  },
];

export default publicRoutes;
