<template>
  <section id="dashboard">
    <enable-mfa-message
      v-if="!$keycloak.authenticated"
      :has-error="serverHasError"
      :referrer-error-message="referrerErrorMessage"
      :does-user-exists="userExistsInReffererDomain"
    />
    <div id="dashboard-wrapper" class="mt-4 mb-8">
      <v-row>
        <v-col cols="12" lg="9">
          <h1 class="text-h5 secondary--font font-weight-large mt-3 ml-4">User Profile</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="9">
          <user-profile-form />
        </v-col>
      </v-row>
    </div>

    <snackbar
      @close="closeSnackbar"
      :override-default="overrideSnackbar"
      :snackbarValue="!!successMessage || !!errorMessage"
      :snackBarType="!!errorMessage ? 'error' : 'success'"
    >
      <template #body v-if="overrideSnackbar">
        <div class="my-2 mx-2">
          <div>
            <div class="d-flex align-baseline">
              <v-icon class="mr-3" :color="$appConfig.commonColors.success">
                mdi-check-circle-outline
              </v-icon>
            </div>

            <div class="snackbar-message">
              <div class="text--primary subtitle-2 font-weight-bold">
                {{ successMessage }}
              </div>
              <div class="text--primary body-2">
                You will now be required to provide two forms of identification
                when logging in.
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else>
        {{ successMessage || errorMessage }}
      </div>
    </snackbar>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import firebaseMixin from "@/mixins/firebase.mixin";
import { getNavigation, getRefferer, getReffererDomain } from "@/utils";
import Snackbar from "@/components/shared/Snackbar.vue";
import EnableMfaMessage from "@/components/shared/EnableMfaMessage.vue";
import UserProfileForm from "@/components/forms/UserProfileForm.vue";
import {
  AUTH_ERROR_CASE_MESSAGES,
  ERROR_MESSAGES,
  IDENTITY_AUTH_ERROR_CODES,
} from "@/constants/app";

/**
 * Application Dasboard
 */
export default {
  name: "Dashboard",
  /**
  |--------------------------------------------------
  | Mixins
  |--------------------------------------------------
  */
  mixins: [firebaseMixin],
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: {
    Snackbar,
    EnableMfaMessage,
    UserProfileForm
  },
  /**
  |--------------------------------------------------
  | Data properties
  |--------------------------------------------------
  */
  data() {
    return {
      verifyEmail: false,
      isLoading: false,
      successMessage: "",
      errorMessage: "",
      userExistsInReffererDomain: true,
      overrideSnackbar: false,
      referrerErrorMessage: "",
      serverHasError: false,
    };
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      token: "auth/token",
      cookieToken: "auth/cookieToken",
      currentUser: "auth/currentUser",
      isEmailVerified: "auth/isEmailVerified",
      redirectedToMarketing: "auth/redirectToMarketing",
      primaryMfaDetail: "auth/primaryMfaDetail",
      isRedirectedToProfile: "auth/isRedirectedToProfile",
      isReffererMaropostProduct: "auth/isReffererMaropostProduct",
      isAuthenticatorAppMfaEnabled: "auth/isAuthenticatorAppMfaEnabled",
    }),
    /**
     * Determines that user should be redirected to refferer domain
     * if he/she have access to that cluster
     * @type {Boolean}
     */
    redirectToMarketing() {
      return !this.serverHasError && this.redirectedToMarketing;
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapActions({
      updateUserSession: "auth/updateUserSession",
      fetchRealtimeToken: "auth/fetchRealtimeToken",
      verifyMaropostSession: "auth/verifyMaropostSession",
      setCurentUserMfaDetails: "auth/setCurentUserMfaDetails",
    }),
    /**
     * Closes snackbar
     */
    closeSnackbar() {
      this.successMessage = "";
      this.errorMessage = "";
      this.overrideSnackbar = false;
    },
    /**
     * Show loader
     */
    showLoader(val) {
      this.isLoading = val;
    },
    showErrorMessage(error) {
      this.errorMessage = error?.message;
    },
    /**
     * isCode
     * @description computes the error code
     * @returns {Boolean}
     */
    isCode(query, code) {
      return query?.code === code;
    },
    /**
     * appendRefferer
     * @description Appends the domain of the refferer to the error messaage
     */
    appendRefferer(message = "") {
      const domainNameExists = message.includes("{{domain_name}}");
      return domainNameExists
        ? message.replace("{{domain_name}}", getReffererDomain())
        : message;
    },
    /**
     * Determines that a user exists on a cluster or not
     * @returns {Boolean} User exists or not
     */
    showReffererErrorMessage({ query }) {
      this.serverHasError = !!(query?.code || query?.message || query?.error);
      const userDoesnotExistIdentifier = "user doesn";
      switch (this.serverHasError) {
        case this.isCode(query, IDENTITY_AUTH_ERROR_CODES.accountNotFound):
          if (!getRefferer()) return;
          this.referrerErrorMessage = this.appendRefferer(
            AUTH_ERROR_CASE_MESSAGES[IDENTITY_AUTH_ERROR_CODES.accountNotFound]
          );
          break;

        case this.isCode(query, IDENTITY_AUTH_ERROR_CODES.userNotFound):
        case query?.error?.toLowerCase()?.includes(userDoesnotExistIdentifier):
        case query?.message
          ?.toLowerCase()
          ?.includes(userDoesnotExistIdentifier):
          this.userExistsInReffererDomain = false;
          break;

        case this.isCode(query, IDENTITY_AUTH_ERROR_CODES.refreshSession):
          this.refreshUserSession();
          break;

        default:
          this.referrerErrorMessage = query?.message ?? ERROR_MESSAGES.default;
          break;
      }
    },
    /**
     * Redirects user to refferer domain if user is logged in, token is present and
     * user exists in refferer marketing {{cluster}} domain
     */
    refreshUserSession() {
      const navigation = getNavigation();

      if (navigation.type !== "reload" && this.redirectToMarketing) {
        this.verifyMaropostSession(this.cookieToken);
      }
    },
  },
  /**
  |--------------------------------------------------
  | Mouned lifecycle hook
  |--------------------------------------------------
  */
  async mounted() {
    await this.fetchCurrentUser();
    await this.showReffererErrorMessage(this.$route);
    this.refreshUserSession();
  },
};
</script>
