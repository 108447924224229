<template>
  <div id="login-form">
    <auth-forms-wrapper
      show-links
      width="390px"
      card-classes="px-0"
      :has-error="!!errorMessage"
    >
      <template #form>
        <alert-message :message="errorMessage" classes="py-3 mb-4" />
        <div id="login-form__wrapper">
          <v-form
            ref="loginForm"
            v-model="isFormValid"
            @submit.prevent="submitHandler"
          >
            <div class="form-group pb-1">
              <v-text-field
                outlined
                class="required"
                label="Email"
                ref="email"
                inputmode="email"
                id="user-email-input__field"
                v-model="formData.email"
                :rules="[required('Email address'), validateEmail]"
              />
            </div>
            <div class="form-group">
              <password-input-field
                is-required
                id="user-password-input__field"
                hideDetails="auto"
                :password="formData.password"
                :rules="[required('', 'Please enter a password.')]"
                @update-password="formData.password = $event"
              />
            </div>
            <div id="forgot-password__link" class="mt-2 d-flex justify-end">
              <router-link to="/forgot-password" class="text-decoration-none">
                <span
                  class="subtitle-2 text--secondary font-weight-bold"
                  v-text="'Forgot Password?'"
                  v-track="'forgot-password-link'"
                />
              </router-link>
            </div>
            <div class="form-group mt-6">
              <loader
                v-if="isLoading"
                with-overlay
                :loading="isLoading"
                :size="52"
                :color="$appConfig.loader.secondary"
              />

              <v-btn
                v-else
                block
                x-large
                type="submit"
                color="loginBtn"
                :disabled="!isFormValid"
                v-track="'login-submit-btn'"
                class="font-weight-bold white--text"
              >
                Login
              </v-btn>
            </div>
            <auth-identity-login-widget
              @loading="isLoading = $event"
              @error="errorMessage = $event"
              @enforce-mfa="computeAndEnforceMfa"
              @initiate-mfa="emitEnterOtp"
            />
            <div class="form-group mt-6">
              <v-btn
                block
                outlined
                x-large
                color="loginBtn"
                v-track="'login-submit-btn'"
                class="font-weight-bold white--text"
                @click="onRedirectToKeycloak"
              >
                Sign in with Keycloak
              </v-btn>
            </div>
          </v-form>
        </div>
      </template>
    </auth-forms-wrapper>

    <salesforce-saml-assertion-form
      @show-loader="isLoading = $event"
      v-if="!isEmpty(samlResponse)"
      :action="samlResponse.saml_acs_url"
      :relay-state="samlResponse.relay_state"
      :saml-response="samlResponse.saml_response"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { required, validateEmail } from "@/validators";
import {
  AUTH_GIP_ERROR_CODES,
  AUTH_ERROR_CASE_MESSAGES,
} from "@/constants/app";
import {
  auth
} from "@/services/auth";

import { getMultiFactorResolver } from 'firebase/auth' 

import PasswordInputField from "@/components/shared/PasswordInputField.vue";

import { isEmpty } from "@/utils";
import Loader from "@/components/shared/Loader.vue";
import AuthFormsWrapper from "@/components/shared/AuthFormsWrapper.vue";

import AlertMessage from "../shared/AlertMessage.vue";
import AuthIdentityLoginWidget from "@/components/widgets/AuthIdentityLoginWidget.vue";
import SalesforceSamlAssertionForm from "@/components/forms/SalesforceSamlAssertionForm.vue";

/**
 * Auth error codes
 */
const authErrorCodes = Object.values(AUTH_GIP_ERROR_CODES);
authErrorCodes.length = 2;

/**
 * Login user form
 * @author {Jatin Kamboj}
 */
export default {
  name: "LoginForm",
  /**
  |--------------------------------------------------
  | Custom events emitted
  |--------------------------------------------------
  */
  emits: ["enter-otp", "enforce-mfa"],
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: {
    Loader,
    AlertMessage,
    AuthFormsWrapper,
    PasswordInputField,
    SalesforceSamlAssertionForm,
    AuthIdentityLoginWidget,
  },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      formData: {
        email: "",
        password: "",
      },
      isLoading: false,
      isFormValid: false,
      errorMessage: "",
      isMutiFactor: false,
      authResolver: null,
    };
  },
  /**
  |--------------------------------------------------
  | Watching properties
  |--------------------------------------------------
  */
  watch: {
    previousUserProvider(val) {
      if (!isEmpty(val) && val.email) this.formData.email = val.email;
      else this.formData.email = "";
    },
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      isMfaEnabled: "auth/isMfaEnabled",
      samlResponse: "auth/samlResponse",
      previousUserProvider: "auth/previousUserProvider",
      enForceMfaUserDetails: "auth/enForceMfaUserDetails",
      isAuthenticatorAppMfaEnabled: "auth/isAuthenticatorAppMfaEnabled",
      is2FAVerificationEnabled: "auth/is2FAVerificationEnabled",
    }),
    /**
     * Logging in user mfa details if mfa is enabled
     */
    mutiFactorDetails() {
      if (!this.authResolver) return {};
      return this.authResolver.hints[0];
    },
    /**
     * Determines if SAML assertion id is present in query params
     * @type {Boolean}
     */
    hasSamlAssertionId() {
      return !!this.$route.query?.maropost_saml_id;
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    isEmpty,
    required,
    validateEmail,
    ...mapMutations({
      setSamlAuthId: "auth/SET_SAML_AUTH_ID",
    }),
    /**
     * Maps vuex action in the component
     */
    ...mapActions({
      signInUser: "auth/signInUser",
      setUserToken: "auth/setUserToken",
      setCurrentUserDetails: "auth/setCurrentUserDetails",
      setKeycloakCurrentUserDetails: "auth/setKeycloakCurrentUserDetails",
      linkPreviousUserProvider: "auth/linkPreviousUserProvider",
      setSkip2FAVerification: "auth/setSkip2FAVerification",
    }),
    /**
     * Set isloading property to show loader
     * @param {Boolean} val Boolean value of isLoading property
     */
    setIsLoading(val) {
      this.isLoading = val;
    },
    /**
     * Determines that mfa needs to be enforced or not
     * @emits enforce-mfa event in parent component to enforce mfa
     */
    enforceMFA() {
      if (
        !this.isMfaEnabled &&
        !isEmpty(this.enForceMfaUserDetails) &&
        Array.isArray(this.enForceMfaUserDetails.enrolledFactors) &&
        this.enForceMfaUserDetails.enrolledFactors.length <= 0
      ) {
        this.$emit("enforce-mfa", true);
      }
    },
    computeAndEnforceMfa() {
      if (!this.isMfaEnabled) this.enforceMFA();
      else if (this.isAuthenticatorAppMfaEnabled)
        this.$emit("enter-totp", true);
    },

    /**
     * Handles login form submition
     * @listens submit
     */
    async submitHandler() {
      try {
        this.errorMessage = "";
        this.isLoading = true;

        await this.setSamlAuthId(this.$route.query?.maropost_saml_id);
        const result = await this.signInUser(this.formData);
        /**
         * Skip 2FA Verification for User for FB App.
         */
        
        if(this.formData.email == 'qa_automation@maropost.com'){
          this.setSkip2FAVerification()
        }

        await this.linkPreviousUserProvider({
          user: result.user,
          email: this.formData?.email,
        });
        this.computeAndEnforceMfa();
      } catch (error) {
        const { code, message } = error;
        switch (code) {
          case "auth/multi-factor-auth-required":
            this.authResolver = getMultiFactorResolver(auth, error);
            this.isMutiFactor = true;
            this.emitEnterOtp(this.authResolver);
            break;

          default:
            this.errorMessage = AUTH_ERROR_CASE_MESSAGES[code] ?? message;
            break;
        }
        this.isLoading = false;
      } finally {
        if (!this.hasSamlAssertionId) this.isLoading = false;
      }
    },
    emitEnterOtp(authResolver) {
      this.$nextTick(() => {
        this.$emit("enter-otp", authResolver);
      });
    },
    onRedirectToKeycloak () {
      this.$keycloak.login({
        redirectUri: `${window.location.origin}/#/dashboard`, // Redirects back to the app's root after login
      });
    }
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  mounted() {
    this.$refs.email?.focus();
  },
};
</script>

<style lang="scss" scoped>
#login-btn__loader {
  height: 52px !important;
}

.remember-me {
  width: 138px !important;
}
</style>
