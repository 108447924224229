import { USER_PROPS } from "../constants/app";
import { userCollection } from "./firebase.js";
import { pick, keycloakObj} from "@/utils";
import { $accounts } from "./apis/http/http";
import { $identity } from "@/services/apis/";
import { doc, setDoc, getDoc} from 'firebase/firestore'

/**
 * Fetches user profile details of logged in user
 */
export const getUserProfile = async (email) => {
  const { baseURL, authenticated } = keycloakObj()
  if (authenticated){
    return $identity.get("account", { baseURL, withCredentials: true });
  } else {
    return await getDoc(doc(userCollection, email));
  }
};

/**
 * Creates a user profile record in users collection
 */
export const createUserProfile = async ({ email, ...data }) => {
  const payload = pick(data, USER_PROPS);
  return await setDoc(doc(userCollection, email), payload)
};

export const userAccounts = (params = {}) => {
  return $accounts.get("accounts.json", { params });
};

/**
 * Updates a user profile record in Keycloak
 * @param {Object} payload - User profile data to update
 * @returns {Promise<Object>} API response
 */
export const updateUserProfile = (payload) => {
  const { baseURL } = keycloakObj()
  return $identity.post("account", payload, { baseURL });
};
export const addUser = (payload,config) => {
  return $identity.post(`session/users/add`, payload,config);
};
