import Keycloak from 'keycloak-js/lib/keycloak';

/**
 * Env config
 */
const ENV_CONFIG = process.env;

/**
 * Keycloak environment variables for configuration
 */ 
const config = {
  url: ENV_CONFIG.VUE_APP_KEYCLOAK_URL,
  realm: ENV_CONFIG.VUE_APP_KEYCLOAK_REALM,
  clientId: ENV_CONFIG.VUE_APP_KEYCLOAK_CLIENT_ID
}


/**
 * Create a Keycloak instance using configuration
 */
const keycloak = new Keycloak(Object.freeze(config));

export default keycloak;
