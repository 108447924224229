import router from "@/router";
import {
  pick,
  isEmpty,
  getRefferer,
  isMaropostProduct,
  camelCase,
  getUrlDomain,
  // toMarketing,
  isLocalDevEnv,
  decodeToken,
  isTokenValid,
  toProductCluster
} from "@/utils";
import {
  signInUser,
  signOutUser,
  getCurrentUser,
  getCurrentUserIdToken,
  setCookie,
  removeCookie,
  getCurrentUserMfaDetails,
  assertSalesforceAuth,
  reauthenticateUserWithCredential,
  auth,
  verifyMarketingSession,
  enrollAuthenticatorAppMfa,
  oauthProvider,
  fetchSignInMethodsForEmail,
} from "@/services/auth";
import { getUserProfile } from "@/services/users";

import {
  AUTH_GIP_ERROR_CODES,
  FIREBASE_USER_DETAILS,
  INVALID_MAROPOST_REFFERERS,
  MFA_OPTIONS,
  RESPONSE_CODES,
} from "@/constants/app.js";
import GainsightMixin from "@/mixins/Gainsight.mixin";
import { manageUserRedirectionAfterLogin } from "@/store/store.utils";
import { linkWithCredential } from "firebase/auth"
/**
|--------------------------------------------------
| Module state
|--------------------------------------------------
*/
const state = {
  currentUser: null,
  isLoggedIn: false,
  token: null,
  cookie: "",
  mfaDetails: null,
  samlAuthId: null,
  samlResponse: null,
  // Determines whether a user was redirected to profile page from a maropost product
  isRedirectedToProfile: false,
  userProfile: null,
  enForceMfaUserDetails: null,
  isGalaxySamlAssertionValid: false,
  submitSamlForm: false,
  isSessionVerifying: false,
  previousUserProvider: null,
  // Maropost active applications
  activeApplications: [],
  signInMethods: null,
  is2FAVerificationEnabled: true,
};

/**
|--------------------------------------------------
| Mutations constants
|--------------------------------------------------
*/
const LOGOUT_USER = "LOGOUT_USER";
const SET_TOKEN = "SET_TOKEN";
const SET_CURRENT_USER = "SET_CURRENT_USER";
const UPDATE_CURRENT_USER_DETAILS = "UPDATE_CURRENT_USER_DETAILS";
const SET_COOKIE_SESSION = "SET_COOKIE_SESSION";
const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
const SET_MULTI_FACTOR_DETAILS = "SET_MULTI_FACTOR_DETAILS";
const SET_SAML_AUTH_ID = "SET_SAML_AUTH_ID";
const SET_SALESFORECE_SAML_ASSERTION = "SET_SALESFORECE_SAML_ASSERTION";
const SET_IS_REDIRECTED_TO_PROFILE = "SET_IS_REDIRECTED_TO_PROFILE";
const SET_USER_PROFILE_DETAILS = "SET_USER_PROFILE_DETAILS";
const SET_ENFORCE_MFA_FOR_USER = "SET_ENFORCE_MFA_FOR_USER";
const REMOVE_ENFORCE_MFA_USER = "REMOVE_ENFORCE_MFA_USER";
const SET_GALAXY_SAML_ASSERTION_VALID = "SET_GALAXY_SAML_ASSERTION_VALID";
const SET_SUBMIT_GALAXY_SAML_FORM = "SET_SUBMIT_GALAXY_SAML_FORM";
const SET_IS_VERIFYING_SESSION = "SET_IS_VERIFYING_SESSION";
const SET_ACTIVE_APPLICATIONS = "SET_ACTIVE_APPLICATIONS";
const REMOVE_ENFORCE_USER_PROFILE_DETAILS =
  "REMOVE_ENFORCE_USER_PROFILE_DETAILS";
const SET_PREVIOUS_USER_PROVIDER = "SET_PREVIOUS_USER_PROVIDER";
const SET_SIGN_IN_METHODS = "SET_SIGN_IN_METHODS";

/**
|--------------------------------------------------
| Mutations
|--------------------------------------------------
*/
const mutations = {
  [SET_CURRENT_USER](state, data) {
    state.currentUser = data;
    state.isLoggedIn = true;
  },
  [LOGOUT_USER](state) {
    state.currentUser = null;
    state.isLoggedIn = false;
    state.token = null;
    state.mfaDetails = null;
    state.samlAuthId = null;
    state.samlResponse = null;
    state.userProfile = null;
    state.enForceMfaUserDetails = null;
    state.isGalaxySamlAssertionValid = false;
    state.submitSamlForm = false;
    state.isSessionVerifying = false;
    state.cookie = "";
    state.activeApplications = [];
    state.signInMethods = null;
  },
  [SET_TOKEN](state, token) {
    state.token = token;
  },
  [SET_COOKIE_SESSION](state, cookie) {
    state.cookie = cookie;
  },
  [UPDATE_USER_DETAILS](state, data) {
    state.currentUser = { ...state.currentUser, ...data };
  },
  [SET_MULTI_FACTOR_DETAILS](state, data) {
    state.mfaDetails = data;
  },
  [SET_SAML_AUTH_ID](state, data) {
    state.samlAuthId = data;
  },
  [SET_SALESFORECE_SAML_ASSERTION](state, data) {
    state.samlResponse = data;
  },
  [UPDATE_CURRENT_USER_DETAILS](state, data) {
    state.currentUser = { ...state.currentUser, ...data };
  },
  [SET_IS_REDIRECTED_TO_PROFILE](state) {
    const isRedirectedToProfile =
    ["/profile", "/preferences","/security"].includes(window.location.hash?.replace("#", ""));

    // is user redirected to profile page or not
    state.isRedirectedToProfile = isRedirectedToProfile;
  },
  [SET_USER_PROFILE_DETAILS](state, data) {
    state.userProfile = { ...state.userProfile, ...data };
  },
  /**
   * Removes details of the user to whom mfa is enforced
   */
  [SET_ENFORCE_MFA_FOR_USER](state, data) {
    const details = pick(data, FIREBASE_USER_DETAILS);
    const enrolledFactors = data?.multiFactor?.enrolledFactors ?? [];

    state.enForceMfaUserDetails = { ...details, enrolledFactors };
  },
  [REMOVE_ENFORCE_MFA_USER](state) {
    state.enForceMfaUserDetails = null;
  },
  [SET_GALAXY_SAML_ASSERTION_VALID](state, currentSamlId) {
    // Compare saml id in localstorage with new saml id
    const isSamlIdNew = currentSamlId
      ? state?.samlAuthId !== currentSamlId
      : false;
    state.isGalaxySamlAssertionValid = isSamlIdNew && isSamlIdNew;
  },
  [SET_SUBMIT_GALAXY_SAML_FORM](state, data) {
    state.submitSamlForm = data;
  },
  [SET_IS_VERIFYING_SESSION](state, data) {
    state.isSessionVerifying = data;
  },
  [REMOVE_ENFORCE_USER_PROFILE_DETAILS](state) {
    state.userProfile = null;
  },
  [SET_PREVIOUS_USER_PROVIDER](state, data) {
    state.previousUserProvider = data;
  },
  /*eslint-disable */
  [SET_ACTIVE_APPLICATIONS](state, data) {
    state.activeApplications = [...(state.activeApplications ?? []), data];
  },
  [SET_SIGN_IN_METHODS](state, data) {
    state.signInMethods = data;
  },
  SET_SKIP_2FA_VERIFICATION(state, value) {
      state.is2FAVerificationEnabled = value
  },
};

/**
|--------------------------------------------------
| Actions (Asynchronous)
|--------------------------------------------------
*/
const actions = {
  /**
   * Set Flag to Skip 2FA
   */
  setSkip2FAVerification({commit}, value=false){
    commit('SET_SKIP_2FA_VERIFICATION',value)
  },
  /**
   * Sends an ajax request to authorise the user on the basis of the credentials
   * commits a mutation to save the user details in the store
   * @param {Object} credentials User credentials who wants to login
   */
  /*eslint-disable */
  async signInUser({ dispatch, commit }, credentials) {
    try {
      const result = await signInUser(credentials);
      const userDetails = await getCurrentUser();
      await commit(SET_ENFORCE_MFA_FOR_USER, userDetails);
      await dispatch("getUserProfileDetails", {
        email: userDetails?.email ?? "",
      });
      return result;
      // await dispatch("setCurrentUserDetails", userDetails);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * Logs outs a user from the application
   */
  async logoutUser({ commit, dispatch }) {
    const { resetSession } = GainsightMixin.methods;
    try {
      await signOutUser();
      await removeCookie();
      await commit("ui/RESET_UI_MODULE", [], { root: true });
    } catch (error) {
      Promise.reject(error);
    } finally {
      await commit(LOGOUT_USER);
      await resetSession();

      await commit(SET_PREVIOUS_USER_PROVIDER, null);
      // Redirect user to login page
      router.push({ name: "logout" });
      localStorage.removeItem("maropost-identity");
    }
  },
  async setUserToken({ commit }, forceRefresh = false) {
    const token = await getCurrentUserIdToken(forceRefresh);
    await commit(SET_TOKEN, token);
    // token && (await commit(SET_TOKEN, token));
  },
  /**
   * Sets current user details and it's identity details in the store
   */
  async setCurrentUserDetails(
    { commit, dispatch, state: { samlAuthId, isLoggedIn }, getters, ...rest },
    { code, setCookie = true, ...data }
  ) {
    try {
      const isUserAlreadyLoggedIn = getters.isLoggedIn;
      const currentUser = pick(data, FIREBASE_USER_DETAILS);
      await dispatch("setUserToken");

      await Promise.allSettled([
        dispatch("getUserProfileDetails", currentUser),
        dispatch("setCurentUserMfaDetails"),
      ]);

      // Authenticates user in multiple clusters of maropost marketing app
      setCookie && (await dispatch("setCookieSession", { code }));
      await dispatch("initialiseGainsightUser", getters.userProfile);

      await commit(SET_CURRENT_USER, currentUser);
      // Maropost galaxy saml login (Authenticates the user in Galaxy app)
      if (samlAuthId) await dispatch("setSalesforceAssertion");

      await commit("ui/SET_ROUTER_HISTORY", router.app?.$route, { root: true });
      await dispatch("setSignInMethods", currentUser?.email ?? "");

      manageUserRedirectionAfterLogin({
        isUserAlreadyLoggedIn,
        getters,
        ...rest,
      });
    } catch (error) {
      isLoggedIn && dispatch("logoutUser");
      return Promise.reject(error);
    }
  },
  async setKeycloakUserToken({ commit }, token) {
    await commit(SET_TOKEN, token);
  },
  async setKeycloakCurrentUserDetails(
    { commit, dispatch, state: { isLoggedIn }, getters, ...rest },
    { code, setCookie = true, ...data }
  ) {
    try {
      
      const currentUser = pick(data, FIREBASE_USER_DETAILS);

      await commit(SET_CURRENT_USER, currentUser);
      await commit(SET_COOKIE_SESSION, {token: data.token, jit: data.id });
      await dispatch("setKeycloakUserToken", data.token );
      await commit(SET_USER_PROFILE_DETAILS, currentUser);
      await dispatch("initialiseGainsightUser", getters.userProfile);
      await commit("ui/SET_ROUTER_HISTORY", router.app?.$route, { root: true });

    } catch (error) {
      isLoggedIn && dispatch("logoutUser");
      return Promise.reject(error);
    }
  },
  async initialiseGainsightUser() {
    const [, userProfile] = arguments;
    const { methods } = GainsightMixin;
    await methods.initialiseUser(userProfile);
  },
  /**
   * Fetches and mutates current logged in user
   * mfa details in the store
   */
  async setCurentUserMfaDetails({ commit }) {
    try {
      const mfaDetails = await getCurrentUserMfaDetails();
      commit(SET_MULTI_FACTOR_DETAILS, mfaDetails);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * Sets cookies for marketing app different clusters
   */
  async setCookieSession({ commit, state: { token } }, details = {}) {
    try {
      const payload = { idToken: token, ...details };
      const { data } = await setCookie(payload);

      await commit(SET_COOKIE_SESSION, data);
      /**
       * Commented code for marketing cloud redirection
       * @warning Donot remove this code
       */
      // toMarketing(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getGipUserObject({ dispatch }) {
    /**
     * Fetches current logged in firebase user object
     */
    return new Promise((resolve, reject) => {
      let unsubscribe;
      unsubscribe = auth.onAuthStateChanged(async (user) => {
        unsubscribe && unsubscribe();
        try {
          if (user && !isEmpty(user)) {
            resolve(user);
          } else {
            dispatch("logoutUser");
          }
        } catch (error) {
          reject(error);
        }
      }, reject);
    });
  },
  /*eslint-disable */
  async fetchRealtimeToken({ dispatch, commit }, hardRefresh = false) {
    try {
      const user = await dispatch("getGipUserObject");
      const token = await user?.getIdToken(hardRefresh);
      await commit(SET_TOKEN, token);
      return token;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * Assert salesforce saml assertion
   */
  async setSalesforceAssertion({
    commit,
    dispatch,
    state: { samlAuthId },
    getters,
  }) {
    try {
      await dispatch("fetchRealtimeToken");

      const payload = { id_token: getters.token, maropost_saml_id: samlAuthId };
      const { data } = await assertSalesforceAuth(payload);

      await commit(SET_SALESFORECE_SAML_ASSERTION, data);
      await commit(SET_SUBMIT_GALAXY_SAML_FORM, true);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * Updates user current session for maropost products
   * @description Removes already existed cookie and create new cookie for the user
   * after mfa have been enabled by him/her
   */
  async updateUserSession({ dispatch }, data = {}) {
    try {
      const {
        removeCookieFirst = true,
        hardRefreshToken = false,
        ...cookie
      } = data;
      if (removeCookieFirst) await removeCookie();

      await dispatch("fetchRealtimeToken", hardRefreshToken);
      await dispatch("setCookieSession", cookie);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * Fetches logged in user profile details from the firestore database
   */
  async getUserProfileDetails({ commit }, { email, ...rest }) {
    try {
      let doc = await getUserProfile(email);
      if (doc.exists) doc = doc.data();
      else doc = {};

      // Converts object properties of an object from pascal case to camelCase
      let profile = {};
      Object.keys(doc).forEach((key) => (profile[camelCase(key)] = doc[key]));

      commit(SET_USER_PROFILE_DETAILS, { ...rest, ...profile, email });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * Re autheicates the logged in user in Maropost Identity
   * @param {Object} [email,password] User credentials to be used to reauthenticate
   * @returns {Promise}
   */
  async reauthenticateUser({ commit }, { email, password }) {
    try {
      const { user } = await reauthenticateUserWithCredential({
        email,
        password,
      });
      const { refreshToken } = user;

      await commit(UPDATE_USER_DETAILS, { refreshToken });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * Logs out current user whose mfa is not enabled
   * while he/her have logged in into GIP associated with identity application
   * @description Removes the data of the temporary user logged in via password
   */
  async logoutEnforceMfaUser({ commit }) {
    try {
      const isLoggedIn = !isEmpty(auth.currentUser);
      if (isLoggedIn) await signOutUser();

      await commit(REMOVE_ENFORCE_MFA_USER);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * Verfies the validity of saml auth id
   */
  async setAndValidateSamlAuthId({ commit }, data) {
    await commit(SET_SALESFORECE_SAML_ASSERTION, {});
    await commit(SET_GALAXY_SAML_ASSERTION_VALID, data.id);
    await commit(SET_SAML_AUTH_ID, data.id);
  },
  /**
   * Verfies and increases the session of the logged in user
   * in different maropost products
   */
  /*eslint-disable */
  async verifyMaropostSession({ commit, dispatch, getters }, data = {}) {
    let [status, isInvalid, cookieToken] = [RESPONSE_CODES.success, false, {}];
    const payload = { removeCookieFirst: true, hardRefreshToken: false };

    try {
      await commit(SET_IS_VERIFYING_SESSION, true);
      const { status, data } = await verifyMarketingSession();
      isInvalid = status === RESPONSE_CODES.unAuthorised;
      const token = data.token;

      if (token && isTokenValid(token)) {
        cookieToken = { token, jti: decodeToken(token)?.jti };
      } else {
        cookieToken = getters.cookieToken;
      }

      if (isInvalid) await dispatch("updateUserSession", payload);
      if (getters.redirectToMarketing) toProductCluster(cookieToken)
    } catch (error) {
      status = error?.response?.status;
      isInvalid = status === RESPONSE_CODES.unAuthorised;

      if (isInvalid) await dispatch("updateUserSession", payload);
      if (getters.redirectToMarketing) toProductCluster(getters.cookieToken);
    } finally {
      commit(SET_IS_VERIFYING_SESSION, false);
    }
  },
  /**
   * Verifies the Authenticator app code entered by the user
   * and enrolls the Mfa setup
   * @returns {String} Api response code
   */
  async verifyAndSetupAuthenticatorAppMfa({ getters, dispatch }, { code }) {
    try {
      await dispatch("setUserToken");
      const payload = { idToken: getters.token, code };

      const { status } = await enrollAuthenticatorAppMfa(payload);
      return status;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async setPreviousUserProvider({ commit }, data = {}) {
    commit(SET_PREVIOUS_USER_PROVIDER, data);
  },
  /*eslint-disable */
  async linkPreviousUserProvider(
    { dispatch, getters: { previousUserProvider } },
    userDetails = {}
  ) {
    try {
      if (
        !isEmpty(previousUserProvider) &&
        userDetails?.email === previousUserProvider?.email
      ) {
        /** @todo Make the auth providers dynamic for future idp configurations */
        var provider = oauthProvider("microsoft.com");
        const authCredentials = provider.credential(
          previousUserProvider.credential
        );
        authCredentials.a = previousUserProvider.credential.a;
        await linkWithCredential(auth?.currentUser, authCredentials)
      } else {
        dispatch("setPreviousUserProvider", {});
      }
    } catch (error) {
      if (AUTH_GIP_ERROR_CODES.providerAlreadyLinked === error?.code) {
        dispatch("setPreviousUserProvider", {});
      } else if (AUTH_GIP_ERROR_CODES.mfaRequired !== error?.code) {
        return Promise.reject(error);
      } else {
        dispatch("setPreviousUserProvider", {});
      }
    }
  },
  async setSignInMethods({ commit }, email) {
    try {
      const methods = await fetchSignInMethodsForEmail(email);
      commit(SET_SIGN_IN_METHODS, methods);
      return methods;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

/**
|--------------------------------------------------
| Getters to access state properties
|--------------------------------------------------
*/
const getters = {
  currentUser({ currentUser }) {
    return currentUser;
  },
  isEmailVerified({ currentUser }) {
    return currentUser?.emailVerified ?? false;
  },
  /**
  |--------------------------------------------------
  | Update the check over here
  |--------------------------------------------------
  */
  isMfaEnabled() {
    const [, { enrolledMfaDetails }] = arguments;
    return !isEmpty(enrolledMfaDetails);
  },
  isLoggedIn({ isLoggedIn }) {
    return isLoggedIn;
  },
  token({ token }) {
    return token;
  },
  mfaDetails({ mfaDetails }) {
    return mfaDetails;
  },
  primaryMfaDetail({ mfaDetails }) {
    return mfaDetails?.[0] ?? {};
  },
  samlAuthId({ samlAuthId }) {
    return samlAuthId ?? "";
  },
  samlResponse({ samlResponse }) {
    return samlResponse ?? "";
  },
  isRedirectedToProfile({ isRedirectedToProfile }) {
    return isRedirectedToProfile;
  },
  /**
   * Determines whether the refferer to identity platform is from maropost product domain or not
   */
  isReffererMaropostProduct() {
    const refferer = getRefferer();
    const domain = refferer ? getUrlDomain(refferer) : "";
    console.log(domain)
    const isDomainInvalid = !Object.values(INVALID_MAROPOST_REFFERERS).includes(
      domain
    );
    return (
      (isMaropostProduct(refferer) || isLocalDevEnv(domain)) && isDomainInvalid
    );
  },
  userProfile({ userProfile }) {
    return { ...userProfile };
  },
  currentUserEmail({ currentUser }) {
    return currentUser?.email ?? "";
  },
  cookieToken({ cookie }) {
    return cookie;
  },
  enForceMfaUserDetails({ enForceMfaUserDetails }) {
    return enForceMfaUserDetails;
  },
  isGalaxySamlAssertionValid({ isGalaxySamlAssertionValid }) {
    return isGalaxySamlAssertionValid;
  },
  submitSamlForm({ submitSamlForm }) {
    return submitSamlForm;
  },
  isSessionVerifying({ isSessionVerifying }) {
    return isSessionVerifying;
  },
  enrolledMfaDetails(state, { primaryMfaDetail, userProfile }) {
    const [textMessageMfa, AuthenticatorAppMfa] = MFA_OPTIONS;

    if (!isEmpty(primaryMfaDetail)) return textMessageMfa;
    else if (
      userProfile?.mfaProvider?.toLowerCase() === AuthenticatorAppMfa.id
    ) {
      return AuthenticatorAppMfa;
    }
    return null;
  },
  isAuthenticatorAppMfaEnabled(state, { userProfile }) {
    const [, AuthenticatorAppMfa] = MFA_OPTIONS;
    return userProfile?.mfaProvider?.toLowerCase() === AuthenticatorAppMfa.id;
  },
  isTextBasedMfaEnabled(state, { enrolledMfaDetails, primaryMfaDetail }) {
    return enrolledMfaDetails?.id === "phone" && !isEmpty(primaryMfaDetail);
  },
  passwordMethodExists({ signInMethods }) {
    return signInMethods && signInMethods.indexOf("password") > -1;
  },
  redirectToMarketing(state, getters) {
    return (
      getters.isLoggedIn &&
      getters.passwordMethodExists &&
      !getters.isRedirectedToProfile &&
      getters.isReffererMaropostProduct &&
      (getters.isAuthenticatorAppMfaEnabled || getters.isTextBasedMfaEnabled)
    );
  },
  previousUserProvider({ previousUserProvider }) {
    return previousUserProvider;
  },
  activeApplications: ({ activeApplications }) => {
    return [...new Set(activeApplications)];
  },
  signInMethods: ({ signInMethods }) => signInMethods,
  is2FAVerificationEnabled({is2FAVerificationEnabled}){
    return is2FAVerificationEnabled;
  }
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
