import { mapActions, mapMutations } from "vuex";
import { isEmpty, pick } from "@/utils";
import { auth } from "@/services/auth";
import { getUserProfile } from "@/services/users";
import { onAuthStateChanged, setPersistence, browserLocalPersistence} from 'firebase/auth'

import { FIREBASE_USER_DETAILS } from "@/constants/app.js";

/**
 * Firebase utilty mixin to used to fetch current user details
 * @author {Jatin Kamboj}
 */
export default {
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      firebaseUser: null,
    };
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapMutations({
      updateUserDetails: "auth/UPDATE_CURRENT_USER_DETAILS",
      setUserDetails: "auth/SET_USER_PROFILE_DETAILS",
    }),
    ...mapActions({
      logoutUser: "auth/logoutUser",
    }),
    /**
     * Persists firebase user in the browser tab
     */
    async persistFirebaseUser() {
      await setPersistence(auth,browserLocalPersistence);
    },

    /**
     * Fetch details of the currently logged-in Keycloak user.
     * Ensures the access token is refreshed if about to expire
     * and extracts user profile details.
     */
    async fetchKeycloakUserDetail() {
      try {
        // Load the user's profile from Keycloak
        const response = await getUserProfile();
        const user = response.data

        // Map custom attributes to user object
        user.phoneNumber = user?.attributes?.phoneNumber?.[0]
        user.companyName = user?.attributes?.companyName?.[0]

        // Remove unnecessary metadata
        delete user.userProfileMetadata;

        // Extract and update required user details
        this.keycloakUser = pick(user, FIREBASE_USER_DETAILS);
        this.updateUserDetails(this.keycloakUser);
        this.setUserDetails(this.keycloakUser);
      } catch (error) {
        console.log('API Error::', error)
      }
    },
    /**
     * fecthes details of current logged in firebase user
     */
    fetchCurrentUser() {
      if(this.$keycloak.authenticated) {
        this.fetchKeycloakUserDetail()
      } else {
        return new Promise((resolve, reject) => {
          const unsubscribe = onAuthStateChanged(auth,(user) => {
            unsubscribe();
            try {
              if (user && !isEmpty(user)) {
                this.firebaseUser = pick(user, FIREBASE_USER_DETAILS);
                this.updateUserDetails(this.firebaseUser);
                resolve(user);
              } else {
                this.logoutUser();
                console.log("User Logged out successfully ...");
              }
            } catch (error) {
              reject(error);
            }
          }, reject);
        });
      }
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  async mounted() {
    if (auth.currentUser) {
      await this.fetchCurrentUser();
    } else {
      this.firebaseUser = auth.currentUser;
    }
  },
};
