import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

import "@/styles/global.scss";

import {
  isDevEnv,
  injectFilters,
  injectInjectables,
  registerDirectives,
} from "@/utils";

import "@/plugins/dayjs";

import keycloak from "@/config/keycloak-config";

Vue.config.productionTip = false;

Vue.config.devtools = isDevEnv();

// Attach the Keycloak instance to Vue's prototype for global access
Vue.prototype.$keycloak = keycloak;

injectInjectables();

/**
 * Registers directives globally in vue
 */
registerDirectives();
injectFilters();

localStorage.setItem("referrer", document.referrer ?? "")


keycloak.init({
  onLoad: "check-sso",  // Automatically checks if the user is already logged in (SSO)
  checkLoginIframe: true,  // Disable periodic login iframe checks for simplicity, this reduces unnecessary requests for login state checks.
  pkceMethod: 'S256',  // Use the S256 PKCE method for enhanced security during the authentication flow.
}).then(() => {
  // Mount the Vue application
  mountVueApp();
}).catch((error) => {
  // Handle initialization failure
  mountVueApp(); // Mount the app even if Keycloak fails
  setTimeout(() => { router.replace('/authentication') })
  console.error('Auth service initialization failed.', error)
})

// Helper function to mount the Vue application
const mountVueApp = () => {
  new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}
