import Vue from "vue";
import store from "@/store";
import { isEmpty, isRefferrerGalaxy, sanitaiseInvalidRoute } from "@/utils";
import { MAROPOST_SAML_ID } from "@/constants/app";
import CacheBuster from "@/services/CacheBuster";
const cacheBuster = new CacheBuster(true);

/**
 * Authenticates galaxy saml authentication request when user is authenticated into the application
 * @description Handles Galaxy SAML assertion when user is authenticated
 * @param {Object} to Vue router to object
 */
const authenticateGalaxySamlRequest = async (to, isUserLoggedIn) => {
  try {
    if (
      to?.query?.[MAROPOST_SAML_ID] &&
      isUserLoggedIn &&
      isRefferrerGalaxy()
    ) {
      await store.dispatch("auth/setAndValidateSamlAuthId", {
        id: to?.query?.[MAROPOST_SAML_ID],
      });
      await store.dispatch("auth/setSalesforceAssertion");
    }
  } catch {
    return true;
  }
};

// @todo Update the check to determine if the query param is valid?
const getConnectorAppParams = (to) => {
  const SHOPIFY_SHOP_VALIDATOR = ".myshopify.com";
  if (!isEmpty(to.query) && to.query?.shop?.endsWith(SHOPIFY_SHOP_VALIDATOR)) {
    store.commit("ui/SET_CONNECTOR_APP_PARAMS", to.query);
  }
};

/**
 * Auth guard router middleware
 * @description before each route, check if user needs auth
 * @param {Object} to - the route to change to
 * @param {Object} from - the previous route
 * @param {Object} next - callback
 */
export const auth = async (to, from, next) => {
  await cacheBuster.setAppVersionCheckerWorker();

  const { getters } = store;
  const keycloak = Vue.prototype.$keycloak;
  const isUserLoggedIn = keycloak.authenticated || getters["auth/isLoggedIn"];
  const isRouteAuthorised = to.matched.some(({ meta }) => meta.requiresAuth);

  await authenticateGalaxySamlRequest(to, isUserLoggedIn);

  sanitaiseInvalidRoute();
  getConnectorAppParams(to);

  await store.commit("auth/SET_IS_REDIRECTED_TO_PROFILE");
  // Redirects user to login page if there are no routes matched in the application
  if (isEmpty(to?.matched)) next({ name: "login" });
  // Redirect user to login page If current route is authorised and user is not logged in
  else if (to.name !== "login" && !isUserLoggedIn && isRouteAuthorised)
    next({ name: "login" });
  /**
   * Redirects user to dashboard page if user is logged in or route is not defined
   */ else if ((!to.name || to.name === "login") && isUserLoggedIn)
    next({ path: "/dashboard" });
  else next();
};
